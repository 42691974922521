import React from "react";

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import styled from '@emotion/styled';
import mediaqueries from '@styles/media';
import { Link } from 'gatsby';
import Icons from "@icons";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="Page Not Found — Andrew Abosh"/>
      <Section>
        <TextContainer>
          <Headings.h1 style={{ marginBottom: "0.25em" }}><span role="img" aria-label="Sad Emoji">😢</span></Headings.h1>
          <Headings.h1 style={{ marginBottom: "1em" }}>404: Page Not Found</Headings.h1>
          <BackButton style={{ marginLeft: "-6px" }} to="/"><Icons.ChevronLeft></Icons.ChevronLeft>Go Home</BackButton>
        </TextContainer>
      </Section>
    </Layout>
  );
}

export default NotFoundPage;

const TextContainer = styled.div`
  position: relative;
  margin-top: 100px;
  z-index: 1;

  ${mediaqueries.tablet`
    text-align: center;
  `}
`;

const BackButton = styled(Link)`
  position: relative;
  display: inline-flex;
  color: ${p => (p.hasError ? p.theme.colors.error : p.theme.colors.accent)};
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }

  svg * {
    fill: ${p => (p.hasError ? p.theme.colors.error : p.theme.colors.accent)};
  }
`;